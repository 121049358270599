@import "../../index.css";

.scrollToTop {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background: #f4e4bc;
    border: 2px solid #b59e60;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    color: #4a3b2c;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    padding: 0;

    &.visible {
        opacity: 0.8;
        visibility: visible;
        transform: translateY(0);
    }

    &:hover {
        transform: translateY(-5px);
        opacity: 1;
        box-shadow: 0 5px 20px rgba(181, 158, 96, 0.5);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(181, 158, 96, 0.5);
    }

    svg {
        width: 24px;
        height: 24px;
    }
}

@media (max-width: 768px) {
    .scrollToTop {
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 40px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}
