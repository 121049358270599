@import "../../index.css";

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0%,
    100% {
        box-shadow: 0 0 15px rgba(181, 158, 96, 0.7);
    }
    50% {
        box-shadow: 0 0 25px rgba(181, 158, 96, 0.9);
    }
}

.roadmapWrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: relative;
    padding: 0 8px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.backButtonWrapper {
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 10px;
    background: #f4e4bc;
    border: 2px solid #b59e60;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a3b2c;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s;
    width: 40px;
    height: 40px;

    svg {
        path {
            stroke: #4a3b2c;
        }
    }

    &:hover {
        transform: scale(1.1);
    }
}

@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    60% {
        transform: scale(0.9);
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 24px auto 0;
}

.roadmapItems {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
    margin-bottom: 24px;
    position: relative;
    padding-top: 30px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 6px;
        background: linear-gradient(to bottom, #b59e60, #f4e4bc, #b59e60);
        background-size: 200% 200%;
        transform: translateX(-50%);
        border-radius: 3px;
        z-index: 1;
        box-shadow: 0 0 15px rgba(181, 158, 96, 0.7);
        animation: gradientFlow 6s ease infinite;
    }
}

@keyframes gradientFlow {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.roadmapItem {
    width: 45%;
    background: #f4e4bc;
    padding: 10px;
    border: 5px solid #b59e60;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    font-family: "Papyrus", sans-serif;
    color: #4a3b2c;
    text-align: justify;
    background: linear-gradient(to bottom, #f4e4bc 0%, #f1d9a5 100%);
    transition:
        transform 0.3s,
        box-shadow 0.3s;
    position: relative;
    margin-bottom: 70px;
    align-self: flex-start;
    z-index: 2;
    animation: fadeIn 0.5s ease-out forwards;
    animation-delay: calc(0.15s * var(--order, 1));
    opacity: 0;

    &:nth-child(1) {
        --order: 1;
    }
    &:nth-child(2) {
        --order: 2;
    }
    &:nth-child(3) {
        --order: 3;
    }
    &:nth-child(4) {
        --order: 4;
    }
    &:nth-child(5) {
        --order: 5;
    }
    &:nth-child(6) {
        --order: 6;
    }
    &:nth-child(7) {
        --order: 7;
    }

    &:hover {
        transform: scale(1.03);
        box-shadow: 0 0 20px rgba(181, 158, 96, 0.8);
    }

    &:nth-child(even) {
        align-self: flex-end;

        &::before {
            content: "";
            position: absolute;
            top: 34px;
            left: -40px;
            width: 40px;
            height: 6px;
            background: #b59e60;
            border-radius: 3px;
        }
    }

    &:nth-child(odd) {
        &::before {
            content: "";
            position: absolute;
            top: 34px;
            right: -40px;
            width: 40px;
            height: 6px;
            background: #b59e60;
            border-radius: 3px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #f4e4bc;
        border: 4px solid #b59e60;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: 2;
    }

    &:nth-child(odd)::after {
        top: 21px;
        right: -16%;
    }

    &:nth-child(even)::after {
        top: 21px;
        left: -16%;
    }

    &.completed {
        background: linear-gradient(to bottom, #f4e4bc 0%, #e9d296 100%);

        &::after {
            background: #b59e60;
        }
    }
}

.phase {
    padding: 15px;
    border: 2px solid #b59e60;
    border-radius: 10px;
    background: #f4e4bc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: #4a3b2c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    position: relative;
    overflow: hidden;
    gap: 10px;

    span {
        vertical-align: middle;
    }
}

.phaseIcon {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    flex-shrink: 0;
}

.description {
    padding: 15px;
    border: 2px solid #b59e60;
    border-radius: 10px;
    background: #f4e4bc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: 1rem;
    line-height: 1.6;

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            position: relative;
            padding-left: 1.5rem;
            margin-bottom: 0.5rem;

            &:before {
                content: "•";
                position: absolute;
                left: 0;
                color: #4a3b2c;
            }

            strong {
                color: #6a5b3c;
            }
        }
    }

    .nestedList {
        margin-top: 0.5rem;
        margin-left: 1rem;

        li {
            &:before {
                content: "◦";
            }
        }
    }
}

.completedMarker {
    margin-top: 15px;
    padding: 10px;
    border: 2px dashed #b59e60;
    border-radius: 8px;
    text-align: center;
    font-style: italic;
    background-color: rgba(181, 158, 96, 0.2);
    color: #6a5b3c;
}

.nestedList {
    margin-left: 0.5rem;
}

.disclaimer {
    width: 100%;
    max-width: 800px;
    margin: 40px auto 20px;
    padding: 15px 20px;
    background: #f4e4bc;
    border: 2px solid #b59e60;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-style: italic;
    color: #6a5b3c;
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .roadmapItems {
        &::before {
            left: 20px;
        }
    }

    .roadmapItem {
        width: 80%;
        margin-left: 40px;
        align-self: flex-start !important;
        margin-bottom: 40px;

        &:nth-child(odd),
        &:nth-child(even) {
            &::before {
                left: -25px;
                right: auto;
                top: 34px;
                width: 20px;
            }

            &::after {
                left: -42px;
                right: auto;
                top: 21px;
            }
        }
    }

    .phase {
        font-size: 1.2rem;
        padding: 10px;
        flex-wrap: wrap;

        span {
            text-align: center;
            width: 100%;
        }
    }

    .phaseIcon {
        margin: 0 auto 5px;
    }

    .description {
        font-size: 0.9rem;
        padding: 10px;
    }

    .nestedList {
        margin-left: 0.5rem;
    }
}

.currentPhase {
    position: relative;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.02);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px #8b4513;
    }
    50% {
        box-shadow: 0 0 15px #8b4513;
    }
    100% {
        box-shadow: 0 0 5px #8b4513;
    }
}
