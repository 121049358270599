@import "../../index.css";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    background: #f4e4bc;
    border: 2px solid #b59e60;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin: 40px 0;
    width: 100%;
}

.chartContainer {
    position: relative;
    width: 600px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    overflow: visible;
}

.segment {
    transition: all 0.3s ease;
    cursor: pointer;
    transform-origin: center center;

    &.highlighted {
        transform: scale(1.05);
        filter: brightness(1.1);
    }
}

.centerText {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f4e4bc;
    border: 2px solid #b59e60;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    box-shadow: 0 0 15px rgba(139, 69, 19, 0.1);

    .total {
        font-size: 32px;
        font-weight: bold;
        color: #8b4513;
    }

    .label {
        font-size: 16px;
        color: #8b4513;
        font-family: "Papyrus", sans-serif;
    }
}

.detailsContainer {
    flex: 1;
    min-width: 600px;
    padding: 20px;
    background: #f4e4bc;
    border: 2px solid #b59e60;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(139, 69, 19, 0.1);
}

.details {
    h3 {
        color: #8b4513;
        font-size: 24px;
        margin-bottom: 20px;
        font-family: "Papyrus", sans-serif;
    }
}

.distributionList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.distributionItem {
    padding: 15px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.5);
    border: 2px solid #b59e60;
    transition: all 0.3s ease;
    cursor: pointer;

    &.highlighted {
        background: rgba(255, 255, 255, 0.9);
        transform: translateX(10px);
        box-shadow: 0 5px 15px rgba(139, 69, 19, 0.1);
        border: 2px solid #8b4513;
    }

    .itemHeader {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 8px;

        .colorIndicator {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 2px solid #f4e4bc;
        }

        h4 {
            flex: 1;
            color: #8b4513;
            font-size: 18px;
            margin: 0;
            font-family: "Papyrus", sans-serif;
        }

        .percentage {
            font-size: 20px;
            font-weight: bold;
            color: #8b4513;
        }
    }

    .description {
        color: #6a5b3c;
        line-height: 1.6;
        font-size: 14px;
        margin: 0;
        text-align: left;
    }
}

.detailedInfo {
    margin-top: 15px;
    padding: 15px;
    background: rgba(139, 69, 19, 0.1);
    border-radius: 8px;
    border: 1px solid rgba(139, 69, 19, 0.2);

    h5 {
        color: #8b4513;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    p {
        color: #4a3b2c;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 15px;
    }
}

.additionalInfo {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(139, 69, 19, 0.2);

    p {
        color: #4a3b2c;
        font-size: 14px;
        line-height: 1.6;
        font-style: italic;
    }
}

.tokenDetails {
    display: flex;
    flex-direction: column;
    gap: 8px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid rgba(139, 69, 19, 0.1);

        &:last-child {
            border-bottom: none;
        }

        span {
            color: #8b4513;
            font-size: 14px;
        }

        strong {
            color: #4a3b2c;
            font-size: 14px;
        }
    }
}

@media (max-width: 992px) {
    .container {
        flex-direction: column;
        padding: 20px;
        gap: 20px;
        align-items: center;
    }

    .chartContainer {
        width: 100%;
        height: 100%;
    }

    .centerText {
        width: 100px;
        height: 100px;

        .total {
            font-size: 24px;
        }

        .label {
            font-size: 12px;
        }
    }

    .detailsContainer {
        width: 100%;
        min-width: auto;
    }

    .distributionList {
        grid-template-columns: 1fr;
    }

    .distributionItem {
        padding: 12px;

        .itemHeader {
            h4 {
                font-size: 16px;
            }

            .percentage {
                font-size: 18px;
            }
        }

        .description {
            font-size: 13px;
        }
    }
}
