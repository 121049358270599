.button {
  background-color: #8b4513;
  border: 2px solid #5c3317;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 160px;
  margin: 8px 0;

  &:hover {
    background-color: #a0522d;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
    transform: translateY(-2px);
  }

  &:active {
    background-color: #5c3317;
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.buttonText {
  color: #f4e4bc;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: "Papyrus", sans-serif;
}

@media (max-width: 768px) {
  .button {
    min-width: 140px;
    padding: 8px 16px;
  }

  .buttonText {
    font-size: 16px;
  }
}
