@import "../../index.css";

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0%,
    100% {
        box-shadow: 0 0 15px rgba(181, 158, 96, 0.7);
    }
    50% {
        box-shadow: 0 0 25px rgba(181, 158, 96, 0.9);
    }
}

@keyframes float {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.tokenomicsWrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: relative;
    padding: 0 8px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.backButtonWrapper {
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 10px;
    background: #f4e4bc;
    border: 2px solid #b59e60;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a3b2c;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s;
    width: 40px;
    height: 40px;

    svg {
        path {
            stroke: #4a3b2c;
        }
    }

    &:hover {
        transform: scale(1.1);
    }
}

@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    60% {
        transform: scale(0.9);
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 24px auto 0;
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    animation: fadeIn 0.5s ease-out forwards;
}

.tokenomicsContainer {
    width: 100%;
    background: #f4e4bc;
    padding: 30px;
    border: 5px solid #b59e60;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    font-family: "Papyrus", sans-serif;
    color: #4a3b2c;
    margin: 40px 0;
    background: linear-gradient(to bottom, #f4e4bc 0%, #f1d9a5 100%);
    transition:
        transform 0.3s,
        box-shadow 0.3s;
    animation: fadeIn 0.5s ease-out forwards;
    animation-delay: 0.15s;
    opacity: 0;

    &:hover {
        transform: scale(1.01);
        box-shadow: 0 0 20px rgba(181, 158, 96, 0.8);
    }
}

.sectionTitle {
    font-size: 2.2rem;
    margin: 2.5rem 0 1.5rem;
    color: #4a3b2c;
    text-align: center;
}

.description {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 2rem;

    p {
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
        text-align: left !important;
    }

    ul {
        padding-left: 1.5rem;
        margin-bottom: 1.5rem;

        li {
            margin-bottom: 1rem;
        }
    }

    strong {
        color: #8e6731;
    }
}

.tokenTypes {
    display: flex;
    gap: 2rem;
    margin: 2rem 0;
    flex-wrap: wrap;
}

.tokenType {
    flex: 1;
    min-width: 300px;
    background: #f4e4bc;
    padding: 1.5rem;
    border-radius: 10px;
    border: 2px solid #b59e60;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    h3 {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        color: #4a3b2c;
        text-align: center;
    }

    p {
        font-size: 1.2rem;
        line-height: 1.8;
    }
}

.rewardsImageContainer {
    width: 100%;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.8s ease-out forwards;
}

.rewardsImage {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    transition:
        transform 0.3s,
        box-shadow 0.3s;

    &:hover {
        transform: scale(1.02);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.4);
    }
}

.tokenDistribution {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 2rem 0;
}

.distributionItem {
    background: #f4e4bc;
    padding: 1.5rem;
    border-radius: 10px;
    border: 2px solid #b59e60;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    h3 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        color: #4a3b2c;
        text-align: center;
    }

    p {
        font-size: 1.2rem;
        line-height: 1.6;
        margin-bottom: 0.8rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.disclaimer {
    width: 100%;
    max-width: 800px;
    margin: 40px auto 20px;
    padding: 15px 20px;
    background: rgba(244, 228, 188, 0.8);
    border: 2px solid #b59e60;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    font-style: italic;
    color: #6a5b3c;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5;
}

.tokenDetails {
    background: rgba(139, 69, 19, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid rgba(139, 69, 19, 0.2);
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        border-bottom: 1px solid rgba(139, 69, 19, 0.1);

        &:last-child {
            border-bottom: none;
        }

        span {
            color: #8b4513;
            font-size: 14px;
        }

        strong {
            color: #4a3b2c;
            font-size: 14px;
        }
    }
}

@media (max-width: 768px) {
    .tokenomicsWrapper {
        padding: 0 15px;
    }

    .title {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .tokenomicsContainer {
        padding: 20px;
    }

    .sectionTitle {
        font-size: 1.8rem;
    }

    .description {
        font-size: 1.1rem;
    }

    .tokenType {
        h3 {
            font-size: 1.4rem;
        }

        p {
            font-size: 1.1rem;
        }
    }

    .distributionItem {
        h3 {
            font-size: 1.3rem;
        }

        p {
            font-size: 1.1rem;
        }
    }
}
